import numericInput from '../design_system/numeric_input';
import { validateForm } from '../helper/validate_form';

onLoad(($) => {
  const $formDiscounts = $('.JS-form-discounts');
  if (!$formDiscounts.length) return;

  const $cardDiscounts = $('.JS-card-discounts');
  const $buttonDiscounts = $('.JS-button-discounts');
  const $buttonSave = $('#submit_button_charge_group');
  const $inputMonthlyInterest = $('#charge_group_monthly_interest');
  const $selectFieldByType = $('.JS-field-by-type');
  const $nestedFields = () => $formDiscounts.find('.nested-fields');
  const nestedFieldsLength = () => $nestedFields().length;
  const $iconInfoDiscounts = () => $('.JS-icon-info-discounts');
  const $formRequired = () => $('input[required="required"]');
  const MAX_FORMS_DISCOUNTS = 3;
  const MIN_VALUE = 0;
  const isButtonDiscountsDisabled = () => nestedFieldsLength() >= MAX_FORMS_DISCOUNTS;

  function handleButtonDiscounts() {
    $buttonDiscounts[`${isButtonDiscountsDisabled() ? 'add' : 'remove'}Class`]('btn-disabled');
  }

  function handleTooltipDiscounts() {
    $buttonDiscounts.parent().attr('data-original-title',
      `${isButtonDiscountsDisabled() ? 'É permitido adicionar até 3 faixas de desconto.' : ''}`);
    $iconInfoDiscounts().attr('data-original-title',
      'Quantidade de dias antes do vencimento para aplicar o desconto no pagamento.').tooltip();
  }

  function handleCardDiscounts(event) {
    const eventInsert = () => {
      $nestedFields().each(function () {
        $cardDiscounts.find('.card-discounts-body').append($(this));
        if ($cardDiscounts.hasClass('d-none')) $cardDiscounts.removeClass('d-none');
      });
    };

    const eventAfter = () => {
      if (!nestedFieldsLength()) $cardDiscounts.addClass('d-none');
    };

    const events = {
      insert: () => eventInsert(),
      after: () => eventAfter(),
    };

    events[event]();
  }

  function isInputDaysBeforeDueDateValid($input) {
    return $input.hasClass('JS-input-days_before_due_date') && Number($input.val()) >= MIN_VALUE && !!$input.val();
  }

  function handleValidateForm() {
    const $formRequiredFill = $formRequired().map(function () {
      if (isInputDaysBeforeDueDateValid($(this))) return true;
      return $(this).val().length > MIN_VALUE && Number($(this).val()) !== MIN_VALUE;
    });
    validateForm({
      $button: $buttonSave,
      $form: $formRequiredFill,
    });
  }

  $formDiscounts.on('cocoon:after-insert', () => {
    numericInput($);
    $('.input--long-percent').mask('##99.99', { reverse: true, placeholder: 'Ex: 11,35' });
    handleCardDiscounts('insert');
    handleButtonDiscounts();
    handleTooltipDiscounts();
    handleValidateForm();
    $formRequired().on('input', handleValidateForm);
  });

  $formDiscounts.on('cocoon:after-remove', () => {
    handleButtonDiscounts();
    handleCardDiscounts('after');
    handleTooltipDiscounts();
    handleValidateForm();
    $formRequired().on('input', handleValidateForm);
  });

  $selectFieldByType.on('change', function () {
    const isEmpty = !$(this).val();
    $inputMonthlyInterest[`${isEmpty ? 'removeAttr' : 'attr'}`]('required', 'required');
    handleValidateForm();
    $formRequired().on('input', handleValidateForm);
  });

  handleButtonDiscounts();
  handleTooltipDiscounts();
  handleValidateForm();
  $formRequired().on('input', handleValidateForm);
});
